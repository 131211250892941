<template>
  <div
    @dragstart="startDrag($event, event)"
    @dragover.prevent
    @click="clickEvent(event)"
    :draggable="
      event.type === 'RESERVED-SLOT' ||
      _moment()(event.startAt).isBefore(new Date())
        ? false
        : true
    "
    class="cursor-pointer"
    :style="`
    border-radius: 2px;
    border: 1px solid ${event.category_color || '#495057'};
    color: ${event.category_color || '#495057'};
    height: ${
      event.type !== 'RESERVED-SLOT'
        ? `${itemHeight(event.category_duration)}px`
        : `${reservedHeight({ start: event.startAt, end: event.endAt })}px`
    }
    `"
    v-tooltip.top="
      `
    <div class='w-full flex justify-between mb-2'>
        <span class='font-EffraR text-black text-lg mr-5'
        ><i class='far fa-clock'></i>
        ${_moment()(event.startAt).format('HH:mm')}-${_moment()(
        event.endAt
      ).format('HH:mm')}</span
        >
        <span class='font-EffraR text-shadow' style='color: ${
          event.category_color || '#000'
        };'>
        <i class='fas fa-stethoscope'></i>
        ${event.category_title || ''}
        </span>
        </div>

        <div class='w-full flex justify-between items-center gap-x-6'>
          <span class='text-left text-black font-EffraR text-lg truncate'>
            ${event.patient_firstName || 'Créneau'} ${
        event.patient_lastName || 'réservé'
      }
          </span>
          <span class='font-EffraR text-shadow text-black'>
            Statut : ${$statusConsultation(event.state) || '--'}
          </span>
      </div>
    `
    "
  >
    <span class="font-EffraR text-base truncate">
      {{ _moment()(event.startAt).format("HH:mm") }} -
      {{ _moment()(event.endAt).format("HH:mm") }},
      {{
        `${event.patient_firstName || "Créneau"} ${
          event.patient_lastName || "réservé"
        }`
      }}
    </span>
    <span v-if="event.type === 'VIDEO-CONSULTATION'"
      ><i class="fas fa-video"></i
    ></span>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import moment from "moment";
// import mezr from "mezr";
let mezr = require("mezr");

export default {
  props: {
    event: Object,
    startDrag: Function,
  },
  methods: {
    _moment() {
      return moment;
    },
    itemHeight(duration) {
      let pixelMintues = (100 / 60) * duration;
      return pixelMintues;
    },
    reservedHeight({ start, end }) {
      let startFormat = moment(start).format("DD/MM/YYYY HH:mm");
      let endFormat = moment(end).format("DD/MM/YYYY HH:mm");

      let nodeStart = document.querySelector(`[title="${startFormat}"]`);

      let nodeEnd = document.querySelector(`[title="${endFormat}"]`);

      return (
        mezr.place({
          element: nodeStart,
          target: nodeEnd,
          position: "left top center center",
        }).top - 13 || 24
      ); // B Pixel
    },
    clickEvent(data) {
      EventBus.$emit("CLICK_EVENT", data);
    },
  },
};
</script>